import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['quizQuestion1', 'quizQuestion2', 'quizQuestion3', 'quizQuestion4', 'quizQuestion5'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "quiz"
    }}>{`Quiz`}</h1>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion1" once={true} question="QUESTION 01: I should discourage my teenager from trying different activities and interests, as they should just focus on one or two they are used to." fallbackResponse={<p>
        The correct answer is: <b>False</b>. Encouraging your teenager to experience a range of social situations will
        help build their social skills and in turn foster greater self-confidence and self-esteem. See{' '}
        <GoTo to="/m6-friends/03-social-situations" mdxType="GoTo">A range of social situations</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion2" once={true} question="QUESTION 02: I should encourage my teenager to develop friendships with people of all ages." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Encouraging your teenager to hang out with young children and older people
        can help them learn different points of view, gain wisdom, and develop leadership skills. See{' '}
        <GoTo to="/m6-friends/05-friends-range-of-ages" mdxType="GoTo">Friends with a range of people of different ages</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion3" once={true} question="QUESTION 03: I should encourage my teenager to do kind things for others." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Encouraging your teenager to do kind things for others will help your
        teenager develop good social skills and friendships. See{' '}
        <GoTo to="/m6-friends/06-good-social-skills" mdxType="GoTo">Good social skills</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion4" once={true} question="QUESTION 04: My friendships are important examples of supportive relationships for my teenager." fallbackResponse={<p>
        The correct answer is: <b>True</b>. By having good friends who are trustworthy and loyal around you, your
        teenager will have a good example to follow. See{' '}
        <GoTo to="/m6-friends/06-good-social-skills" mdxType="GoTo">Good social skills</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion5" once={true} question="QUESTION 05: I should avoid talking with my teenager about any friendship difficulties they may be having, to give them privacy." fallbackResponse={<p>
        The correct answer is: <b>False</b>. If your teenager is having friendship difficulties, listen to their
        concerns and ask questions in a curious way, to help them problem solve their own difficulties and navigate the
        complex nature of teenage friendships. See{' '}
        <GoTo to="/m6-friends/07-friendship-troubles" mdxType="GoTo">Friendship troubles</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <SingleImage gridSize={4} smallGridSize={8} src="/images/shared/Oriental_quiz.svg" alt="thinking man" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      